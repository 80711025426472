import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import ContentMobile from './ContentMobile';
import Content from './Content';

const HeaderDesktop = styled(Content)`
  display: none;

  ${up('mobile')} {
    display: block;
  }
`;

const HeaderMobile = styled(ContentMobile)`
  display: block;

  ${up('mobile')} {
    display: none;
  }
`;

const StyledHeader = styled.header``;

const Header = () => {
  return (
    <StyledHeader>
      <HeaderMobile />
      <HeaderDesktop />
    </StyledHeader>
  );
};
export default Header;
