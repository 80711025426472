import styled, { css, useTheme } from 'styled-components';
import ChevronBackOutline from 'react-ionicons/lib/ChevronBackOutline';
import ChevronForwardOutline from 'react-ionicons/lib/ChevronForwardOutline';
import CloseOutline from 'react-ionicons/lib/CloseOutline';
import LogoFacebook from 'react-ionicons/lib/LogoFacebook';
import LogoInstagram from 'react-ionicons/lib/LogoInstagram';
import LogoPinterest from 'react-ionicons/lib/LogoPinterest';
import MenuSharp from 'react-ionicons/lib/MenuSharp';

import CartBag from '../../Cart/CartBag';
import PATHS from '../../paths';
import Logo from '../Logo';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';

const SUBCATEGORIES = {
  cuadros: ['cuadros-lineal', 'laminas-nasa'],
  skateboard: []
};

const LabelMenuTitle = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  z-index: 6;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, 10px);
  width: fit-content;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1),
    transform 0.3s cubic-bezier(0.19, 1, 0.22, 1), visibility 0s 0.3s;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.3rem;

  &.show-label {
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, 0);
    transition: opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0.1s,
      transform 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0.1s, visibility 0s 0s;
  }
`;

const StyledLogo = styled.div`
  position: absolute;
  z-index: 5;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0.1s,
    transform 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0.1s, visibility 0s 0s;

  &.hide-logo {
    visibility: hidden;
    opacity: 0;
    transform: translate(-50%, -10px);
    transition: opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1),
      transform 0.3s cubic-bezier(0.19, 1, 0.22, 1), visibility 0s 0.3s;
  }

  ${({ isIndexPage }) =>
    isIndexPage &&
    css`
      a {
        color: #ffffff;
      }
    `};
`;

const StyledCartBag = styled.div`
  margin-left: auto;

  svg {
    ${({ isIndexPage }) =>
      isIndexPage &&
      css`
        color: #ffffff;
        fill: #ffffff;
      `};
  }
`;

const StyledMobile = styled.div`
  position: fixed;
  height: ${({ theme }) => theme.headerHeightMobile};
  top: 4px;
  left: 4px;
  width: calc(100% - 0.8rem);
  z-index: 10;

  &.menu-open {
    svg {
      fill: #000000;
      color: #000000;
    }

    ${StyledLogo} {
      a {
        color: #313131;
      }
    }
  }
`;

const Container = styled.div`
  transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  border: 1px solid transparent;
  background: transparent;
  height: 100%;
  padding: 0 1.2rem;
  display: flex;
  align-items: center;

  &.header-sticky {
    background: rgba(255, 255, 255, 0.95);
    border-color: #eaeaea;
    backdrop-filter: saturate(180%) blur(20px);
    background-color: rgba(255, 255, 255, 0.72);
    transition: background-color 0.5s cubic-bezier(0.28, 0.11, 0.32, 1);
    transition-property: background-color, backdrop-filter;

    svg {
      color: #000000;
      fill: #000000;
    }

    ${StyledLogo} {
      a {
        color: #313131;
      }
    }
  }
`;

const MenuTrigger = styled.button`
  background: none;
  width: 2.6rem;
  height: 2.6rem;
  position: absolute;
  z-index: 1;
  pointer-events: all;
  top: calc(50px / 2);
  left: 1.2rem;
  transform: translateY(-50%);

  .icon-menu,
  .icon-close-menu,
  .icon-back-menu {
    transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    //position: absolute;
    //left: 0;
    //top: 0;
  }
`;

const StyleActionIcon = styled(({ as: Component, ...props }) => <Component {...props} />)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const MenuBackdrop = styled.div`
  position: absolute;
  border: 1px solid #eaeaea;
  background: #fff;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  transform-origin: top;
  transform: scaleY(0);
  opacity: 0;
  transition: transform 0.25s cubic-bezier(0.19, 1, 0.22, 1) 0.15s, opacity 0s 0.4s;
`;

const MenuNavigation = styled.nav`
  top: 0;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.3s, opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0s,
    transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);

  &.submenu-open {
    transform: translateX(-100%);
  }
`;

const FooterMenu = styled.ul`
  margin-top: auto;

  li {
    text-align: center;
    line-height: 3.25;

    &.social-media a {
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

const PrimaryMenu = styled.ul``;

const PrimaryMenuItem = styled.li`
  border-top: 1px solid #eaeaea;

  > button,
  > a {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    background: transparent;
    font-weight: 600;
    width: 100%;
    padding: 2.1rem 1.6rem;
    text-align: left;
    font-size: 1.3rem;
  }
`;

const Submenu = styled.ul`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(100%);
  display: flex;
  flex-direction: column;
  visibility: hidden;
  transition: visibility 0s 0.4s;

  li {
    border-top: 1px solid #eaeaea;
  }

  &.active {
    visibility: visible;
    transition: visibility 0s 0s;
  }

  a {
    display: block;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    padding: 2.1rem 1.6rem;
    text-align: left;
  }
`;

const Menu = styled.div`
  height: calc(100vh - 0.8rem);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  overflow: hidden;
  pointer-events: none;

  .icon-back-menu {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }

  &.trigger-back {
    .icon-back-menu {
      opacity: 1;
      visibility: visible;
      z-index: unset;
    }

    .icon-close-menu {
      opacity: 0;
      visibility: hidden;
      z-index: -1;
    }
  }

  &.trigger-open {
    pointer-events: all;

    .icon-menu {
      opacity: 0;
      visibility: hidden;
      z-index: -1;
    }

    ${MenuBackdrop} {
      opacity: 1;
      transform: scaleY(1);
      transition: transform 0.4s cubic-bezier(0.19, 1, 0.22, 1), opacity 0s 0s;
    }

    ${MenuNavigation} {
      visibility: visible;
      opacity: 1;
      transition: visibility 0s, opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0.2s,
        transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    }
  }

  &.trigger-close {
    .icon-close-menu {
      opacity: 0;
      visibility: hidden;
      z-index: -1;
    }
  }

  ${MenuNavigation}, ${Submenu} {
    padding-top: ${({ theme }) => theme.headerHeightMobile};
    padding-bottom: 2.4rem;

    @media screen and (min-height: 600px) {
      padding-bottom: 6.4rem;
    }
  }
`;

const ContentMobile = ({ className }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSubMenu, setActiveSubmenu] = useState(null);
  const [headerSticky, setHeaderSticky] = useState(false);
  const theme = useTheme();
  const { pathname } = useLocation();
  const isIndexPage = pathname === '/';

  useEffect(() => {
    detectHeaderHeight();

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', detectHeaderHeight);
    }

    return () => {
      window.removeEventListener('scroll', detectHeaderHeight);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeMenu = () => {
    setActiveSubmenu(null);
    setIsMenuOpen(false);
  };

  const data = useStaticQuery(graphql`
    query MenuQueryMobile {
      subcategories: allShopifyCollection(filter: { productsCount: { gt: 0 } }) {
        nodes {
          handle
          title
          description
          image {
            originalSrc
            altText
            gatsbyImageData(placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
          }
        }
      }
    }
  `);

  const getSubcategoryByHandle = (handles = []) => {
    return data.subcategories.nodes.filter(({ handle: categoryHandle }) =>
      handles.includes(categoryHandle)
    );
  };

  const detectHeaderHeight = () =>
    setHeaderSticky(window.pageYOffset > Number(theme.headerHeightMobile.replace('rem', '')) * 10);

  return (
    <StyledMobile className={`${className} ${isMenuOpen ? 'menu-open' : ''}`}>
      <Container className={headerSticky ? 'header-sticky' : ''}>
        <Menu
          className={`${isMenuOpen ? 'trigger-open' : 'trigger-close'} ${
            activeSubMenu ? 'trigger-back' : ''
          }`}
        >
          <MenuTrigger type="button">
            <StyleActionIcon
              as={MenuSharp}
              width={'24px'}
              height={'24px'}
              className="icon-menu"
              color={isIndexPage ? '#ffffff' : '#000000'}
              onClick={() => setIsMenuOpen(true)}
            />
            <StyleActionIcon
              as={CloseOutline}
              width={'24px'}
              height={'24px'}
              className="icon-close-menu"
              onClick={() => setIsMenuOpen(false)}
            />
            <StyleActionIcon
              as={ChevronBackOutline}
              width={'24px'}
              height={'24px'}
              className="icon-back-menu"
              onClick={() => setActiveSubmenu(null)}
            />
          </MenuTrigger>

          <MenuNavigation className={activeSubMenu ? 'submenu-open' : ''}>
            {/* Primary menu*/}
            <PrimaryMenu>
              {Object.keys(SUBCATEGORIES).map((subcategory, index) => (
                <PrimaryMenuItem key={index}>
                  {Object.keys(getSubcategoryByHandle(SUBCATEGORIES[subcategory])).length ? (
                    <>
                      {/* with childrens */}
                      <button onClick={() => setActiveSubmenu(subcategory)}>
                        {subcategory}

                        <ChevronForwardOutline />
                      </button>

                      {/* Secondary menu*/}
                      <Submenu className={activeSubMenu === subcategory ? 'active' : ''}>
                        {getSubcategoryByHandle(SUBCATEGORIES[subcategory]).map(subcategoryData => (
                          <li key={subcategoryData.handle}>
                            <Link to={`/${subcategoryData.handle}`} onClick={closeMenu}>
                              {subcategoryData.title}
                            </Link>
                          </li>
                        ))}
                      </Submenu>
                    </>
                  ) : (
                    <Link to={`/${subcategory}`} onClick={closeMenu}>
                      {subcategory}
                    </Link>
                  )}
                </PrimaryMenuItem>
              ))}
            </PrimaryMenu>

            <FooterMenu>
              <li>
                <Link to="/sobre-nosotros" onClick={closeMenu}>
                  Sobre nosotros
                </Link>
              </li>

              <li className="social-media">
                <a href={PATHS.instagramSocialMedia} target="_blank" rel="noreferrer noopener">
                  <LogoInstagram />
                </a>
                <a href={PATHS.pinterestSocialMedia} target="_blank" rel="noreferrer noopener">
                  <LogoPinterest />
                </a>
                <a href={PATHS.facebookSocialMedia} target="_blank" rel="noreferrer noopener">
                  <LogoFacebook />
                </a>
              </li>
            </FooterMenu>
          </MenuNavigation>

          <MenuBackdrop />
        </Menu>

        <LabelMenuTitle className={activeSubMenu ? 'show-label' : ''}>
          {activeSubMenu}
        </LabelMenuTitle>

        <StyledLogo className={activeSubMenu ? 'hide-logo' : ''} isIndexPage={isIndexPage}>
          <Link to={PATHS.base} onClick={closeMenu}>
            <Logo />
          </Link>
        </StyledLogo>

        <StyledCartBag isIndexPage={isIndexPage}>
          <CartBag />
        </StyledCartBag>
      </Container>
    </StyledMobile>
  );
};

export default ContentMobile;
