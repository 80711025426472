import styled from 'styled-components';
import CreditCardList from './CreditCardList';
import { down, up } from 'styled-breakpoints';
import AddOutline from 'react-ionicons/lib/AddOutline';
import HeartOutline from 'react-ionicons/lib/HeartOutline';
import RemoveOutline from 'react-ionicons/lib/RemoveOutline';
import { useState } from 'react';
import PATHS from '../paths';
import { Link } from 'gatsby';
import { decodeEmail } from '../utils/email';

const Content = styled.div`
  ${down('mobile')} {
    visibility: hidden;
    max-height: 0;
    opacity: 0;
    transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1) 0.3s;

    &.active {
      opacity: 1;
      visibility: visible;
      max-height: 20rem;
      display: block;
    }
  }
`;

const Socialmedia = styled.div`
  grid-area: social;

  div div {
    display: flex;
    flex-direction: column;
    padding-top: 1.4rem;
  }

  a {
    white-space: nowrap;
    display: inline-block;
    line-height: 2.5rem;
    width: fit-content;
  }
`;

const Contact = styled.div`
  grid-area: contact;

  div {
    padding-top: 1.4rem;
    display: flex;
    flex-direction: column;
    line-height: 2.5rem;
  }
`;

const Info = styled.div`
  grid-area: info;

  div div {
    padding-top: 1.4rem;
    display: flex;
    flex-direction: column;
    line-height: 2.5rem;
  }
`;

const About = styled.div`
  grid-area: about;

  p {
    padding-top: 1.4rem;
    padding-right: 2rem;
    max-width: 45rem;
  }
`;

const Payment = styled.div`
  grid-area: payment;
`;

const MadeClaim = styled.div`
  grid-area: newsletter;
  color: #757575;
  font-size: 1.2rem;
  display: flex;

  ${down('mobile')} {
    display: none;
  }

  span {
    margin-left: 0.5rem;
  }

  svg {
    fill: #757575;
    color: #757575;
    transition: all 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53) 0.35s;
  }

  &:hover svg {
    fill: #b33a3a;
    color: #b33a3a;
  }
`;

const Meta = styled.div`
  grid-area: meta;

  a {
    margin-right: 1rem;
    display: inline-block;
    font-size: 1.1rem;
    font-weight: 500;
  }

  ${down('mobile')} {
    display: flex;
    flex-direction: column;

    a {
      margin: 0;
      padding: 0.5rem 0;
    }
  }
`;

const Address = styled.div`
  grid-area: address;

  div div {
    padding-top: 1.4rem;
  }
`;

const IconAccordion = styled(({ className, isOpen }) =>
  isOpen ? (
    <RemoveOutline className={className} width={'14px'} height={'14px'} />
  ) : (
    <AddOutline className={className} width={'14px'} height={'14px'} />
  )
)`
  display: none;
`;

const StyledFooter = styled.footer`
  display: grid;
  grid-template-columns: 100%;
  row-gap: 0;
  grid-template-areas:
    'about'
    'address'
    'info'
    'social'
    'contact'
    'newsletter'
    'payment'
    'meta';
  margin: 10rem 1.6rem 0;
  padding-bottom: 3.5rem;
  max-width: 100%;

  .hover-underline {
    width: fit-content;
  }

  ${up('mobile')} {
    gap: 2.4rem;
    margin-top: 140px;
    grid-template-columns: 100%;
    row-gap: 0;
  }

  ${up('lg')} {
    margin: 16rem 4rem 8rem;
    gap: 3.2rem;
    padding-bottom: 2.4rem;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    row-gap: 5rem;
    grid-template-areas: 'about about address contact info social' 'newsletter newsletter newsletter payment payment meta';
  }

  ${up('desktop')} {
    margin: 18rem 4rem 8rem;
    row-gap: 5rem;
  }

  h3 {
    font-weight: 600;
    font-size: 1.3rem;
  }

  p {
    line-height: 2.5rem;
  }

  ${Contact},
  ${Meta},
  ${Payment} {
    margin-top: 2.2rem;

    ${up('mobile')} {
      margin-top: 3rem;
    }

    ${up('lg')} {
      margin-top: unset;
    }
  }

  ${About},
  ${Address},
  ${Socialmedia},
  ${Info} {

    ${down('mobile')} {
      overflow: hidden;

      h3 {
        border-bottom: 1px solid #eaeaea;
        padding: 1.5rem 0;
        width: 100%;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-between;

        ${IconAccordion} {
          display: block;
        }
      }

      &.open {
        ${Content} {
          opacity: 1;
          visibility: visible;
          max-height: 200px;
        }
      }
    }
`;

const Footer = () => {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const onClickAccordion = active => {
    const result = activeAccordion !== active ? active : null;
    setActiveAccordion(result);
  };

  return (
    <StyledFooter>
      <About className={activeAccordion === 'about' ? 'open' : ''}>
        <h3 onClick={() => onClickAccordion('about')}>
          Sobre nosotros <IconAccordion isOpen={activeAccordion === 'about'} />
        </h3>

        <Content>
          <p>
            Somos un grupo de jovenes que pretende crear una marca sencilla con objetos de
            decoración que hagan brillar los espacios del hogar con un aspecto único, especial y
            rompedor.
          </p>
        </Content>
      </About>

      <Address className={activeAccordion === 'address' ? 'open' : ''}>
        <h3 onClick={() => onClickAccordion('address')}>
          Nuestra direccion <IconAccordion isOpen={activeAccordion === 'address'} />
        </h3>

        <Content>
          <div>
            <p>
              <a href="https://goo.gl/maps/zxw2XKXMvt92">
                Calle Hermanos Lumiere 5
                <br />
                28500 Arganda del Rey (Madrid)
                <br />
                España
              </a>
            </p>
          </div>
        </Content>
      </Address>

      <Contact>
        <h3>Contacto</h3>

        <div>
          <a href={decodeEmail(PATHS.emailEncode)} className="hover-underline">
            Escríbenos
          </a>
          <a href="tel:918729809" className="hover-underline">
            (+34) 918 72 98 09
          </a>
        </div>
      </Contact>

      <Info className={activeAccordion === 'info' ? 'open' : ''}>
        <h3 onClick={() => onClickAccordion('info')}>
          Información <IconAccordion isOpen={activeAccordion === 'info'} />
        </h3>

        <Content>
          <div>
            <Link to={PATHS.shippingPolicy} className="hover-underline">
              Envíos
            </Link>
            <Link to={PATHS.returnPolicy} className="hover-underline">
              Devoluciones
            </Link>
            <span>Gastos de envío gratis para peninsula</span>
          </div>
        </Content>
      </Info>

      <Socialmedia className={activeAccordion === 'socialmedia' ? 'open' : ''}>
        <h3 onClick={() => onClickAccordion('socialmedia')}>
          Síguenos <IconAccordion isOpen={activeAccordion === 'socialmedia'} />
        </h3>

        <Content>
          <div>
            <a
              href={PATHS.instagramSocialMedia}
              target="_blank"
              rel="noreferrer noopener"
              className="hover-underline"
            >
              Instagram
            </a>
            <a
              href={PATHS.pinterestSocialMedia}
              target="_blank"
              rel="noreferrer noopener"
              className="hover-underline"
            >
              Pinterest
            </a>
            <a
              href={PATHS.facebookSocialMedia}
              target="_blank"
              rel="noreferrer noopener"
              className="hover-underline"
            >
              Facebook
            </a>
          </div>
        </Content>
      </Socialmedia>

      <MadeClaim>
        Creado artesanalmente por nosotros mismos en España
        <HeartOutline width="12px" height="12px" />
      </MadeClaim>

      <Payment>
        <CreditCardList />
      </Payment>

      <Meta>
        <Link to={PATHS.legalNotice}>Aviso legal</Link>
        <Link to={PATHS.termsConditions}>Condiciones generales</Link>
        <Link to={PATHS.privacyPolicy}>Política de privacidad</Link>
        <Link to={PATHS.cookiesPolicy}>Política de cookies</Link>
      </Meta>
    </StyledFooter>
  );
};

export default Footer;
