import MenuMobile from '../MenuMobile';
import { Link } from 'gatsby';
import PATHS from '../../paths';
import Logo from '../Logo';
import CartBag from '../../Cart/CartBag';
import styled, { css, useTheme } from 'styled-components';
import MenuSharp from 'react-ionicons/lib/MenuSharp';
import { down } from 'styled-breakpoints';
import Menu from '../Menu';
import { useLocation } from '@reach/router';
import { useEffect, useState } from 'react';

const StyledMenu = styled(({ handleOpenMenu, ...props }) => (
  <Menu onOpenMenu={handleOpenMenu} {...props} />
))`
  margin-left: 3.2rem;
`;

const MenuLogo = styled.div`
  //position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: color 1.25s cubic-bezier(0.19, 1, 0.22, 1);

  .logo-link {
    color: inherit;
  }

  ${({ isIndexPage }) =>
    isIndexPage &&
    css`
      color: #313131;
    `};

  &.menu-is-open {
    color: #313131;
  }
`;

const Navigation = styled.div`
  display: flex;
  //position: relative;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  white-space: nowrap;

  padding: 3.1rem 0 2.1rem;
  width: 100%;

  ${down('mobile')} {
    padding: 1.7rem 0;
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 100%;
`;

const StyledContent = styled.div`
  max-width: 100vw;
  z-index: 10;
  height: ${({ theme }) => theme.headerHeight};
  position: fixed;
  right: 0;
  left: 1rem;
  top: 1rem;
  width: calc(100% - 2rem);
  padding-left: 3rem;
  padding-right: 3rem;
  display: flex;
  align-items: center;

  &.header-sticky {
    ${MenuLogo} {
      ${({ isIndexPage }) =>
        isIndexPage &&
        css`
          color: #313131;
        `};
    }
  }
`;

const IconMenuMobile = styled(MenuSharp)`
  display: none;

  ${down('mobile')} {
    display: block;
    margin-right: 2rem;
  }
`;

const RightContent = styled.div`
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 2.5rem;
  font-weight: 500;
`;

const Content = ({ className }) => {
  const [headerSticky, setHeaderSticky] = useState(false);
  const [isMenuOpened, setMenuOpen] = useState(false);
  const theme = useTheme();
  const { pathname } = useLocation();
  const isIndexPage = pathname === '/';

  const detectHeaderHeight = () =>
    setHeaderSticky(window.pageYOffset > Number(theme.headerHeight.replace('rem', '')) * 10);

  useEffect(() => {
    detectHeaderHeight();

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', detectHeaderHeight);
    }

    return () => {
      window.removeEventListener('scroll', detectHeaderHeight);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenMenu = isOpen => {
    setMenuOpen(isOpen);
  };

  return (
    <StyledContent
      className={`${className} ${headerSticky ? 'header-sticky' : ''}`}
      isIndexPage={isIndexPage}
    >
      <Container>
        <Navigation>
          <MenuLogo isIndexPage={isIndexPage} className={isMenuOpened ? 'menu-is-open' : ''}>
            <IconMenuMobile />

            <MenuMobile />

            <Link to={PATHS.base} className="logo-link">
              <Logo />
            </Link>

            <StyledMenu handleOpenMenu={handleOpenMenu} />
          </MenuLogo>

          <RightContent>
            <div>
              <Link to="/sobre-nosotros" className="hover-underline">
                sobre nosotros
              </Link>
            </div>
            {/* @toDo PRONTO */}
            {/*<SearchProducts />*/}
            {/*<LanguageSelector />*/}
            <CartBag />
          </RightContent>
        </Navigation>
      </Container>
    </StyledContent>
  );
};

export default Content;
