import styled from 'styled-components';

const StyledButton = styled.button`
  position: relative;
  width: auto;
  appearance: none;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  line-height: 2.2rem;
  white-space: nowrap;
  cursor: pointer;
  border: 0;
  padding: 0.8rem 1.6rem;
  transition: opacity 0.3s ease-in-out 0s;
  color: #fff;
  background-color: #181818;
  font-weight: 600;
  font-size: 1.6rem;

  &:hover {
    opacity: 0.8;
  }
`;

const Button = props => {
  const { children, type = 'button', ...other } = props;

  return (
    <StyledButton type={type} {...other}>
      {children}
    </StyledButton>
  );
};

export default Button;
