import Modal from 'styled-react-modal';
import styled, { keyframes } from 'styled-components';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import ChevronForwardOutline from 'react-ionicons/lib/ChevronForwardOutline';
import CloseOutline from 'react-ionicons/lib/CloseOutline';
import { useDispatch, useSelector } from 'react-redux';
import CartSummaryLineItem from './CartSummaryLineItem';
import { goToCheckout, removeItem } from '../state/actions/cart';
import ButtonIcon from '../components/Button/ButtonIcon';
import Button from '../components/Button';
import { priceFormat } from './utils/price';
import LoadingSpinner from '../components/LoadingSpinner';
import { down } from 'styled-breakpoints';
import { GAEventRemoveFromCart } from '../utils/GAEvents';
import PATHS from '../paths';
import { navigate } from 'gatsby';

const modalAnimation = keyframes`
  0% {
    transform: scaleY(0);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
  }
`;

const StyledModal = Modal.styled`
  margin: 1.6rem;
  cursor: default;
  
  ${down('mobile')} {
    margin: 0;
    position: absolute;
  }
`;

const ModalLayout = styled.div`
  display: flex;
  flex-direction: column;
  top: 0;
  right: 3.2rem;
  margin-right: 1.6rem;
  padding: 0;
  //width: 38.4rem;
  width: 48rem;
  overflow: hidden;
  min-height: 24rem;
  max-height: calc(100vh - 3rem);
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 5%) 0 0.4rem 2rem;

  ${down('mobile')} {
    max-height: 100vh;
    width: calc(100vw - 1rem);
    right: unset;
    left: -0.5rem;
    top: 0.5rem;
    position: relative;
    margin-right: 0;
  }
`;

const ModalContainer = styled.div`
  transform-origin: top;
  animation: ${modalAnimation} 0.3s cubic-bezier(0.19, 1, 0.22, 1) forwards;
  transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1), opacity 0s 0s;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.8rem;
  flex: 0 1 auto;
  overflow-y: auto;
`;

const ModalEmpty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1 0;

  p {
    margin-bottom: 2rem;
  }
`;

const ModalTitle = styled.div`
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.08rem;
`;

const ModalFooter = styled.div`
  padding: 1.6rem;
  background-color: rgb(249, 249, 249);
  align-items: center;
  justify-content: space-between;
  display: flex;
`;

const SummaryPrice = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.6rem;
`;

const ModalHeader = styled.div`
  display: flex;
  border-bottom: 0.1rem dashed rgba(0, 0, 0, 0.1);
  padding: 1.2rem 1.6rem;
  justify-content: space-between;
  align-items: center;
`;

const TotalPrice = styled.p`
  font-size: 1.6rem;
  font-weight: 600;
`;

const MinText = styled.p`
  color: #929292;
  font-size: 1.1rem;
  font-weight: 400;
`;

const StyledButton = styled(Button)`
  display: block;
  width: 90%;
  margin: 0 auto;
  border-radius: 0;
  font-weight: 400;
  padding: 1.6rem 6.5rem;
  font-size: 1.6rem;
  letter-spacing: 0.05rem;
  line-height: unset;
  background-color: #252a32;
`;

const Close = styled.a`
  cursor: pointer;
`;

const CheckoutButton = styled(ButtonIcon)`
  margin-left: auto;
`;

const CartModalSummary = ({ isOpen = false, onCloseModal = () => {} }) => {
  const { checkout, loadingCheckout, loadingBuy } = useSelector(({ cart }) => cart);
  const dispatch = useDispatch();

  const handleRemoveItem = item => {
    dispatch(removeItem(item.id));
    GAEventRemoveFromCart(item);
  };

  const handleCheckout = () => {
    dispatch(goToCheckout());
  };

  const handleCloseModal = () => {
    document.body.style.overflow = '';
    onCloseModal();
  };

  const handleClickEmptyCartButton = () => {
    handleCloseModal();
    navigate(PATHS.base, { replace: true });
  };

  const isCheckoutEmpty = () => {
    if (checkout && (!checkout.lineItems || !checkout.lineItems.length)) {
      return true;
    }

    if (!checkout) {
      return true;
    }

    return false;
  };

  return (
    <StyledModal
      isOpen={isOpen}
      onBackgroundClick={handleCloseModal}
      onEscapeKeydown={handleCloseModal}
      backgroundProps={{
        position: 'right'
      }}
      allowScroll={true}
    >
      <ModalContainer>
        <ModalLayout>
          <ModalHeader>
            <ModalTitle>
              <FormattedMessage id="cart.shoppingCart" />
            </ModalTitle>
            <Close onClick={handleCloseModal}>
              <CloseOutline width={'24px'} height={'24px'} />
            </Close>
          </ModalHeader>

          {isCheckoutEmpty() && (
            <ModalEmpty>
              {loadingCheckout ? (
                <LoadingSpinner />
              ) : (
                <>
                  <p>
                    <FormattedMessage id="cart.empty" />
                  </p>
                  <StyledButton onClick={handleClickEmptyCartButton}>
                    <FormattedMessage id="cart.backShop" />
                  </StyledButton>
                </>
              )}
            </ModalEmpty>
          )}

          {checkout && checkout.lineItems && checkout.lineItems.length > 0 && (
            <>
              <ModalBody>
                {checkout.lineItems.map(item => (
                  <CartSummaryLineItem
                    key={item.id.toString()}
                    item={item}
                    onRemoveItem={handleRemoveItem}
                  />
                ))}
              </ModalBody>

              <SummaryPrice>
                <div>
                  <TotalPrice>
                    <FormattedMessage id="cart.subtotal" />
                  </TotalPrice>
                  <MinText>
                    <FormattedMessage
                      id={checkout.taxesIncluded ? 'cart.taxIncluded' : 'cart.taxNotIncluded'}
                    />
                  </MinText>
                  <MinText>
                    <FormattedMessage id="cart.shippingPriceInfo" />
                  </MinText>
                </div>

                <div>
                  <TotalPrice>{priceFormat(checkout.subtotalPriceV2)}</TotalPrice>
                </div>
              </SummaryPrice>

              <ModalFooter>
                {/*<Link to={PATHS.cart} className="underline">*/}
                {/*  <FormattedMessage id="cart.view" />*/}
                {/*</Link>*/}

                <CheckoutButton
                  onClick={handleCheckout}
                  isLoading={loadingBuy}
                  icon={<ChevronForwardOutline />}
                >
                  <FormattedMessage id="checkout" />
                </CheckoutButton>
              </ModalFooter>
            </>
          )}
        </ModalLayout>
      </ModalContainer>
    </StyledModal>
  );
};

export default CartModalSummary;
