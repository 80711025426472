function encode(mail) {
  const key = Math.floor(Math.random() * 256);
  let result = ('0' + key.toString(16)).slice(-2);
  for (let position = 0; position < mail.length; position++) {
    const byte = mail.charCodeAt(position) ^ key;
    result += ('0' + byte.toString(16)).slice(-2);
  }
  return result;
}

function parseHex(string, position) {
  const result = string.substr(position, 2);
  return parseInt(result, 16);
}

export const decodeEmail = (href, startPos = 0) => {
  let result = '';
  const key = parseHex(href, startPos);
  for (let position = startPos + 2; position < href.length; position = position + 2) {
    const byte = parseHex(href, position) ^ key;
    result = result + String.fromCharCode(byte);
  }
  return result;
};
