exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-aviso-legal-js": () => import("./../../../src/pages/legal/aviso-legal.js" /* webpackChunkName: "component---src-pages-legal-aviso-legal-js" */),
  "component---src-pages-legal-condiciones-generales-js": () => import("./../../../src/pages/legal/condiciones-generales.js" /* webpackChunkName: "component---src-pages-legal-condiciones-generales-js" */),
  "component---src-pages-legal-politica-cookies-js": () => import("./../../../src/pages/legal/politica-cookies.js" /* webpackChunkName: "component---src-pages-legal-politica-cookies-js" */),
  "component---src-pages-legal-politica-devoluciones-js": () => import("./../../../src/pages/legal/politica-devoluciones.js" /* webpackChunkName: "component---src-pages-legal-politica-devoluciones-js" */),
  "component---src-pages-legal-politica-envio-js": () => import("./../../../src/pages/legal/politica-envio.js" /* webpackChunkName: "component---src-pages-legal-politica-envio-js" */),
  "component---src-pages-legal-politica-privacidad-js": () => import("./../../../src/pages/legal/politica-privacidad.js" /* webpackChunkName: "component---src-pages-legal-politica-privacidad-js" */),
  "component---src-pages-sobre-nosotros-js": () => import("./../../../src/pages/sobre-nosotros.js" /* webpackChunkName: "component---src-pages-sobre-nosotros-js" */),
  "component---src-product-containers-product-detail-container-js": () => import("./../../../src/Product/containers/ProductDetailContainer.js" /* webpackChunkName: "component---src-product-containers-product-detail-container-js" */),
  "component---src-product-containers-product-list-container-js": () => import("./../../../src/Product/containers/ProductListContainer.js" /* webpackChunkName: "component---src-product-containers-product-list-container-js" */)
}

