import styled, { keyframes } from 'styled-components';

const loaderLine = keyframes`
  0% {
    transform: translateX(-150%);
  }

  45% {
    transform: translateX(0);
  }

  55% {
    transform: translateX(0);
  }

  to {
    transform: translateX(150%);
  }
`;

const StyledLoading = styled.div`
  position: relative;
  display: block;
  height: 0.2rem;
  width: 30%;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  margin: auto;
  pointer-events: none;
  background-color: #e3e3e3;
  z-index: -1;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  opacity: 1;
  visibility: visible;
  z-index: 1;

  &:before {
    position: absolute;
    content: '';
    bottom: 0;
    right: 0;
    top: 0;
    height: 100%;
    width: 200%;
    background-color: #454545;
    animation: ${loaderLine} 1.4s ease-in-out infinite;
    transition: opacity 0.3s ease-in-out;
  }
`;

const LoadingSpinner = () => {
  return <StyledLoading />;
};

export default LoadingSpinner;
