import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

export const GDPR_COOKIES = {
  GDPR_NOTICE: 'gdprNotice',
  GDPR_SETTINGS: 'gdprSettings'
};

export const GDPR_COOKIES_VALUES = {
  ALL: ['analytics', 'marketing'],
  MARKETING: ['marketing'],
  ANALYTICS: ['analytics']
};

const DEFAULT_COOKIES_EXPIRE_DAYS = 365; // days

export const useCookiesGDPR = () => {
  const [isAccepted, setAcceptedCookies] = useState(true);
  const [cookiesGDPR, setCookiesGDPR] = useState([]);

  useEffect(() => {
    setAcceptedCookies(!!Cookies.get(GDPR_COOKIES.GDPR_NOTICE));
  }, []);

  useEffect(() => {
    if (isAccepted) {
      setConsents();
      // createGDPRCookies(cookiesGDPR);
    } else {
      disableConsents();
    }
  }, [isAccepted]);

  useEffect(() => {
    if (cookiesGDPR.length) {
      setAcceptedCookies(true);
      createGDPRCookies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookiesGDPR]);

  const createGDPRCookies = () => {
    Cookies.set(GDPR_COOKIES.GDPR_NOTICE, 'accepted', { expires: DEFAULT_COOKIES_EXPIRE_DAYS });
    Cookies.set(GDPR_COOKIES.GDPR_SETTINGS, JSON.stringify(cookiesGDPR), {
      expires: DEFAULT_COOKIES_EXPIRE_DAYS
    });
  };

  const disableConsents = () => {
    // google analytics
    if (typeof window.gtag !== 'undefined') {
      window.gtag('consent', 'default', {
        ad_storage: 'denied',
        analytics_storage: 'denied'
      });
      dataLayer.push({
        event: 'default_consent'
      });
    }
    // facebook
    typeof fbq !== 'undefined' && fbq('consent', 'revoke');
  };

  const setConsents = () => {
    if (!Cookies.get(GDPR_COOKIES.GDPR_SETTINGS)) {
      return false;
    }

    try {
      const gdprTypes = JSON.parse(Cookies.get(GDPR_COOKIES.GDPR_SETTINGS));
      if (gdprTypes && gdprTypes.length) {
        if (gdprTypes.includes('analytics')) {
          // google analytics
          typeof window.gtag !== 'undefined' &&
            window.gtag('consent', 'update', {
              ad_storage: 'granted',
              analytics_storage: 'granted'
            });
        }

        if (gdprTypes.includes('marketing')) {
          // facebook
          typeof fbq !== 'undefined' && fbq('consent', 'grant');
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  return [isAccepted, setCookiesGDPR, disableConsents];
};
