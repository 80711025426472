import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import cart from './reducers/cart';
import product from './reducers/product';
import thunk from 'redux-thunk';

const windowGlobal = typeof window !== 'undefined' && window;

// const devtools =
//   process.env.NODE_ENV === 'development' && windowGlobal.devToolsExtension
//     ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
//     : f => f;
const devtools = windowGlobal.devToolsExtension
  ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  : f => f;

const reducers = combineReducers({
  cart,
  product
});

const store = createStore(reducers, compose(applyMiddleware(thunk), devtools));

export default store;
