import styled from 'styled-components';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { useEffect, useState } from 'react';
import SubMenu from './SubMenu';

const StyledMenu = styled.nav`
  .category-link {
    margin: 0 1.4rem;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 0;
    color: inherit;
  }
`;

const List = styled.ul`
  display: flex;

  button {
    background: transparent;
  }
`;

const SUBCATEGORIES = {
  cuadros: ['cuadros-lineal', 'laminas-nasa']
};

const Menu = ({ onOpenMenu = () => {}, ...props }) => {
  const [isOpenSubMenu, setIsOpenSubMenu] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  const [categorySelected, setCategorySelected] = useState(null);

  const onClickCategory = categoryParent => {
    setIsOpenSubMenu(true);
    setCategorySelected(categoryParent);
  };

  const handleClose = () => {
    setIsOpenSubMenu(false);
  };

  useEffect(() => {
    onOpenMenu(isOpenSubMenu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenSubMenu]);

  const data = useStaticQuery(graphql`
    query MenuQuery {
      subcategories: allShopifyCollection(filter: { productsCount: { gt: 0 } }) {
        nodes {
          handle
          title
          description
          image {
            originalSrc
            altText
            gatsbyImageData(placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
          }
        }
      }
    }
  `);

  useEffect(() => {
    const result = {};
    Object.keys(SUBCATEGORIES).map(categoryParent => {
      result[categoryParent] = getSubcategoryByHandle(SUBCATEGORIES[categoryParent]);
    });

    setSubCategories(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getSubcategoryByHandle = (handles = []) => {
    return data.subcategories.nodes.filter(({ handle: categoryHandle }) =>
      handles.includes(categoryHandle)
    );
  };

  return (
    <StyledMenu {...props}>
      <List>
        <li>
          <button
            type="button"
            onClick={() => onClickCategory('cuadros')}
            onMouseEnter={() => onClickCategory('cuadros')}
            className="category-link hover-underline a-link"
          >
            cuadros
          </button>
        </li>

        <li>
          <Link
            to={'/skateboard'}
            onMouseEnter={() => setIsOpenSubMenu(false)}
            className="category-link hover-underline"
          >
            skateboard
          </Link>
        </li>
      </List>

      <SubMenu
        isOpen={isOpenSubMenu}
        onClose={handleClose}
        categorySelected={categorySelected}
        subCategories={subCategories}
      />
    </StyledMenu>
  );
};

export default Menu;
