export const initialState = { sortType: 'name', products: [] };

const reducer = (state = initialState, { type, payload, ...action }) => {
  switch (type) {
    case 'SET_FILTER_PRODUCT_LIST':
      return {
        ...state,
        sortType: payload
      };
    case 'SET_PRODUCT_LIST':
      return {
        ...state,
        products: payload
      };
    default:
      return state;
  }
};

export default reducer;
