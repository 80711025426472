import { Provider } from 'react-redux';

import store from './src/state/store';

const WrapWithProvider = ({ element }) => {
  // Instantiating store in `wrapWithStyle` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  return <Provider store={store}>{element}</Provider>;
};

export default WrapWithProvider;
