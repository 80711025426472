import BagOutline from 'react-ionicons/lib/BagOutline';
import styled from 'styled-components';
import CartModalSummary from './CartModalSummary';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hasItemsCart, showCartSummary } from '../state/actions/cart';

const IconBag = styled(BagOutline)`
  position: relative;
  cursor: pointer;

  &:after {
    content: '';
    background: rgb(228, 88, 78);
    width: 7px;
    height: 7px;
    position: absolute;
    border-radius: 50%;
    bottom: 3px;
    right: 0;
    opacity: 0;
    transition: opacity 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53) 0.35s;
  }

  &.has-products {
    &:after {
      opacity: 1;
    }
  }
`;

const StyledCart = styled.button`
  background: transparent;
`;

const CartBag = () => {
  const [hasProducts, setHasProducts] = useState(false);
  const dispatch = useDispatch();
  const { isCartSummaryOpen: isOpen, checkout } = useSelector(({ cart }) => cart);

  function toggleModal() {
    dispatch(showCartSummary(!isOpen));
  }

  useEffect(() => {
    setHasProducts(dispatch(hasItemsCart()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkout]);

  return (
    <StyledCart type="button">
      <IconBag
        className={hasProducts ? 'has-products' : ''}
        onClick={toggleModal}
        height="22px"
        width="22px"
      />
      {isOpen && <CartModalSummary isOpen={isOpen} onCloseModal={toggleModal} />}
    </StyledCart>
  );
};

export default CartBag;
