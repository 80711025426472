import styled, { css, keyframes } from 'styled-components';

const backgroundAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const BaseModalBackground = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 30;
  background-color: rgba(0, 0, 0, 0.3);
  animation: ${backgroundAnimation} 1s cubic-bezier(0.19, 1, 0.22, 1);
  ${({ position }) =>
    (!position || position === 'center') &&
    css`
      align-items: center;
      justify-content: center;
    `}

  ${({ position }) =>
    (!position || position === 'right') &&
    css`
      justify-content: flex-end;
    `}
`;
