import styled, { css, keyframes } from 'styled-components';
import { cloneElement, isValidElement } from 'react';

const StyledIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  color: ${({ theme }) => theme.color.white};
  margin: 1.6rem 2.5rem 1.6rem 0;
  ${({ isLoading }) => (isLoading ? 'opacity: 0' : 'opacity: 1')};
  transition: opacity 0.4s cubic-bezier(0.19, 1, 0.22, 1) 0.2s;

  ${({ iconIsActive }) => iconIsActive && 'opacity: 1'};
`;

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 1.6rem 6.5rem;
  background-color: ${({ theme }) => theme.color.primary};
  text-align: center;
  position: relative;
  color: ${({ theme }) => theme.color.white};
  font-size: 1.3rem;
  font-weight: 600;
  letter-spacing: 0.05rem;
  border-radius: 0;
  transition: opacity 0.3s ease-in-out;

  ${({ variant }) =>
    variant === 'invert' &&
    css`
      background-color: white;
      color: ${({ theme }) => theme.color.primary};

      ${StyledIcon} svg {
        color: ${({ theme }) => theme.color.primary};
        fill: ${({ theme }) => theme.color.primary};
      }
    `}

  ${({ variant }) =>
    variant === 'secondary' &&
    css`
      background-color: transparent;
      color: ${({ theme }) => theme.color.primary};
      border: 0.01rem solid ${({ theme }) => theme.color.primary};

      ${StyledIcon} svg {
        color: ${({ theme }) => theme.color.primary};
        fill: ${({ theme }) => theme.color.primary};
      }
    `}

  ${({ variant }) =>
    variant === 'secondary-invert' &&
    css`
      background-color: transparent;
      color: white;
      border: 0.01rem solid white;

      ${StyledIcon} svg {
        color: white;
        fill: white;
      }
    `}

  &[disabled] {
    opacity: 0.8;
    border: 0;
    cursor: not-allowed;

    ${StyledIcon} {
      opacity: 1;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &:hover {
    opacity: 0.9;

    ${StyledIcon} {
      opacity: 1;
    }
  }
`;

const loaderLine = keyframes`
  0% {
    transform: translateX(-150%);
  }

  45% {
    transform: translateX(0);
  }

  55% {
    transform: translateX(0);
  }

  to {
    transform: translateX(150%);
  }
`;

const Text = styled.span`
  display: inline-block;
  position: relative;
  overflow: hidden;
  padding: 0 2rem;

  &:before {
    display: ${({ isLoading }) => (isLoading ? 'flex' : 'none')};
    content: '';
    position: absolute;
    justify-content: center;
    animation: ${loaderLine} 1.4s ease-in-out infinite;
    height: 2px;
    width: 120%;
    background-color: #fff;
    max-width: 100%;
    left: 0;
    top: 50%;
    transition: opacity 0.3s ease-in-out;
  }
`;

const ButtonIcon = ({
  type = 'button',
  icon,
  variant,
  iconIsActive = false,
  isLoading = false,
  disabled = false,
  children,
  ...props
}) => {
  const Icon = () => {
    if (isValidElement(icon)) {
      return cloneElement(icon, { color: '#fff', width: '16px', height: '16px' });
    }

    return icon;
  };

  return (
    <StyledButton disabled={isLoading || disabled} type={type} variant={variant} {...props}>
      <Text isLoading={isLoading}>{children}</Text>

      {!isLoading && (
        <StyledIcon iconIsActive={iconIsActive} isLoading>
          <Icon />
        </StyledIcon>
      )}
    </StyledButton>
  );
};

export default ButtonIcon;
