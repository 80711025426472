import styled from 'styled-components';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import { priceFormat } from './utils/price';
import { Link } from 'gatsby';
import { getShopifyImage } from 'gatsby-source-shopify';
import { GatsbyImage } from 'gatsby-plugin-image';

const Actions = styled.div`
  margin-top: 1rem;
`;

const ProductHeader = styled.div`
  flex-direction: column;
  display: flex;
`;

const Variant = styled.div`
  opacity: 0.5;
  font-weight: 400;
  margin: 0;
  padding: 0;
  font-size: 1.4rem;
  line-height: 2rem;
  min-height: 2rem;
`;

const Title = styled(Link)`
  font-weight: 500;
  margin: 0;
  padding: 0;
  font-size: 1.4rem;
  line-height: 2rem;
`;

const Content = styled.div`
  width: auto;
  display: flex;
`;

const ImageContainer = styled.div`
  margin-right: 1.5rem;
  max-width: 7.5rem;
  width: 7.5rem;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
  }
`;

const PriceContent = styled.div``;

const Quantity = styled.span`
  display: inline-block;
  margin-right: 1rem;
`;

const VariantPrice = styled.div`
  margin: 0;
  padding: 0;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
`;

const StyledLineItem = styled.div`
  width: 100%;
  padding: 0.96rem;
  display: flex;
  justify-content: space-between;
`;

const CartSummaryLineItem = ({ item, onRemoveItem = () => {} }) => {
  const handleRemoveItem = () => {
    onRemoveItem(item);
  };

  const amountPriceQuantity = Number(item.variant.price) * item.quantity;

  const variantImage = {
    ...item.variant.image,
    originalSrc: item.variant.image.src
  };

  const image = getShopifyImage({
    image: variantImage,
    layout: 'constrained',
    width: 200
  });

  return (
    <StyledLineItem>
      <Content>
        <ImageContainer>
          <Link to={`/${item.variant.product.handle}`}>
            <GatsbyImage
              key={variantImage.src}
              image={image}
              alt={variantImage.altText || item.variant.title}
            />
          </Link>
        </ImageContainer>

        <ProductHeader>
          <Title to={`/${item.variant.product.handle}`}>{item.title}</Title>
          <Variant>{item.variant.title !== 'Default Title' && item.variant.title}</Variant>

          <Actions>
            <Quantity>x{item.quantity}</Quantity>
            <a onClick={handleRemoveItem} className="underline">
              <FormattedMessage id="remove" />
            </a>
          </Actions>
        </ProductHeader>
      </Content>
      <PriceContent>
        <VariantPrice>
          {priceFormat({ ...item.variant.price, amount: amountPriceQuantity })}
        </VariantPrice>
      </PriceContent>
    </StyledLineItem>
  );
};

export default CartSummaryLineItem;
