import styled from 'styled-components';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useEffect, useRef } from 'react';

const StyledSubMenu = styled.div`
  display: flex;
  flex-direction: column;
  visibility: hidden;
  transition: visibility 0s 0.1s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
  padding: 9rem 3rem 6.5rem;
  z-index: -1;

  ul {
    //display: grid;
    //grid-template-columns: repeat(4, 1fr);
    display: flex;
    flex-flow: row wrap;
    position: relative;
    visibility: hidden;
    height: 0;
    transform: translateY(0.5rem);
    transition: opacity 0.8s cubic-bezier(0.19, 1, 0.22, 1),
      transform 1.5s cubic-bezier(0.19, 1, 0.22, 1) 0.2s;
    grid-gap: 0 3.2rem;
    opacity: 0;

    li {
      flex: 0 0 33.333333%;
      flex: 0 0 25%;
      overflow: hidden;
    }

    &.subcategory-active {
      opacity: 1;
      visibility: visible;
      height: auto;
      transform: none;
    }

    a {
      position: relative;
      display: block;
    }
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 0.2rem);
    height: calc(100% - 0.2rem);
    border: 0.1rem solid #eaeaea;
    background: #fff;
    transform-origin: top;
    content: '';
    transform: scaleY(0);
    opacity: 0;
    transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0.1s, opacity 0s 0.4s;
    z-index: -1;
  }

  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: rgba(0, 0, 0, 0.4);
    opacity: 0;
    z-index: -2;
    transition: opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  }

  &.active {
    pointer-events: all;
    visibility: visible;
    transition: visibility 0s 0s;

    ul {
      //transform: none;
      //transition: transform 1.5s cubic-bezier(0.19, 1, 0.22, 1) 0.2s;
    }

    &:after {
      opacity: 1;
      transform: scaleY(1);
      transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.2s;
    }

    &:before {
      opacity: 1;
    }
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  padding-bottom: 72.2222222222%;
  position: relative;
  height: 0;
  overflow: hidden;
  background: #eaeaea;

  .gatsby-image-wrapper {
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;

const SubCategoryLabel = styled.span`
  margin-top: 1.6rem;
  font-weight: 600;
  white-space: nowrap;
  display: block;
  width: fit-content;
`;

const SubCategoryDescription = styled.p`
  display: block;
  font-weight: 400;
  margin-top: 0.4rem;
  white-space: break-spaces;
`;

const SubMenu = ({ isOpen = false, categorySelected, subCategories = [], onClose = () => {} }) => {
  const refContainer = useRef(null);

  useEffect(() => {
    refContainer.current && refContainer.current.addEventListener('mouseleave', onClose);
    return () => {
      refContainer.current && refContainer.current.removeEventListener('mouseleave');
    };
  }, [refContainer]);

  return (
    <StyledSubMenu className={isOpen ? 'active' : ''} ref={refContainer}>
      {Object.keys(subCategories).map((subCategory, i) => (
        <ul
          key={subCategory}
          className={isOpen && subCategory === categorySelected ? 'subcategory-active' : ''}
        >
          {subCategories[subCategory].map((category, index) => (
            <li key={index}>
              <Link to={`/${category.handle}`} onClick={onClose}>
                {category.image && (
                  <ImageWrapper>
                    <GatsbyImage
                      layout="fluid"
                      image={getImage(category.image)}
                      alt={category.image.altText || ''}
                    />
                  </ImageWrapper>
                )}

                <SubCategoryLabel>{category.title}</SubCategoryLabel>
                <SubCategoryDescription>{category.description}</SubCategoryDescription>
              </Link>
            </li>
          ))}
        </ul>
      ))}
    </StyledSubMenu>
  );
};

export default SubMenu;
