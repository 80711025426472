import styled from 'styled-components';

const StyledLogo = styled.div`
  img {
    height: 25px;
  }

  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 2.5rem;
  font-weight: 400;
  letter-spacing: 0.5px;

  font-weight: 500;
  letter-spacing: 0;
`;

const Logo = () => {
  return (
    <StyledLogo>
      hoøprint
      {/*<img*/}
      {/*  alt="Logo"*/}
      {/*  src="https://cdn.freebiesupply.com/images/large/2x/amazon-logo-transparent.png"*/}
      {/*/>*/}
    </StyledLogo>
  );
};

export default Logo;
