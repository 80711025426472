import GlobalStyle from '../theme/globalStyle';
import { ModalProvider } from 'styled-react-modal';
import { BaseModalBackground } from '../components/BaseModalBackground';
import styled, { ThemeProvider } from 'styled-components';
import { theme } from '../theme/theme';
import { down } from 'styled-breakpoints';
import { useEffect } from 'react';
import { loadCart } from '../state/actions/cart';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import PATHS from '../paths';
import Header from '../components/Header/Header';
import Footer from '../components/Footer';
import CookiesBanner from '../components/CookiesBanner';

const StyledMain = styled.main`
  max-width: 100%;
  margin: 8rem auto 0;
  margin-top: 0;

  ${down('mobile')} {
    margin-top: 0;
  }
`;

const Layout = ({ children, location }) => {
  const dispatch = useDispatch();
  const showCookiesBanner = location.pathname !== PATHS.cookiesPolicy;

  useEffect(() => {
    dispatch(loadCart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ModalProvider backgroundComponent={BaseModalBackground}>
        <GlobalStyle />

        <Helmet>
          <link rel="dns-prefetch" href="https://fonts.googleapis.com" />
          <link rel="dns-prefetch" href="https://fonts.gstatic.com" />
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "hooprint",
                "description": "Decora tu espacio con algo diferente",
                "url": "https://hooprint.com",
                "sameAs": ["${PATHS.instagramSocialMedia}"]
              }
          `}
          </script>
        </Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />

        <Header />

        <StyledMain>{children}</StyledMain>

        <Footer />
        {showCookiesBanner ? <CookiesBanner /> : null}
      </ModalProvider>
    </ThemeProvider>
  );
};

export default Layout;
