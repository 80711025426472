export const theme = {
  breakpoints: {
    mobile: '768px', // 768px
    lg: '992px', // 992px
    // desktop: '1200px', // 1200px
    // desktop: '1920px', // 1200px
    desktop: '1200px',
    // xxl: '1900px'
    // xxl: '2457.6px' // 1536px
    xxl: '1536px',
    extraxxl: '1924px'
  },
  global: {
    fontSizeBody: '1.4rem',
    fontColor: '#313131',
    // fontFamily: "'Nunito', Helvetica, sans-serif"
    fontFamily: "'Raleway', Helvetica, sans-serif"
  },
  color: {
    primary: '#252a32',
    white: '#ffffff'
  },
  headerHeight: '7.7rem',
  headerHeightMobile: '5rem'
};
