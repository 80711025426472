const PATHS = {
  base: '/',
  cart: '/cart',
  category: '',
  returnPolicy: '/legal/politica-devoluciones/',
  shippingPolicy: '/legal/politica-envio/',
  privacyPolicy: '/legal/politica-privacidad/',
  legalNotice: '/legal/aviso-legal/',
  termsConditions: '/legal/condiciones-generales/',
  cookiesPolicy: '/legal/politica-cookies/',
  instagramSocialMedia: 'https://instagram.com/hooprint.es',
  facebookSocialMedia: 'https://www.facebook.com/Hooprint-106782448049482/',
  pinterestSocialMedia: 'https://www.pinterest.es/hooprint/',
  emailEncode: '325f535b5e465d085b5c545d725a5d5d42405b5c461c515d5f'
};

export default PATHS;
