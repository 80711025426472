import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import { down, up } from 'styled-breakpoints';
import fonts from './fonts';
const GlobalStyle = createGlobalStyle`
  ${reset}
  ${fonts}

  *, *:before, *:after {
    box-sizing: border-box;
  }
  
  html {
    box-sizing: border-box;
    font-size: 62.5%;
  }
  
  body {
    font-family: ${({ theme }) => theme.global.fontFamily};
    color: ${({ theme }) => theme.global.fontColor};
    font-size: ${({ theme }) => theme.global.fontSizeBody};
  }
  
  select {
    font-family: ${({ theme }) => theme.global.fontFamily};
  }
  
  main {
   
  }
  
  .container {
    //max-width: 1680px;
    max-width: 190rem; //  prueba @todo
    overflow: hidden; //  prueba @todo
    margin: 0 auto;
  }
  
  button {
    font-family: inherit;
    border: 0;
    padding: 0;
  }

  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }
  
  a,
  button.a-link {
    text-decoration: none;
    color: ${({ theme }) => theme.global.fontColor};
    
    &.underline {
      opacity: .9;
      text-decoration: none;
      position: relative;
      padding-bottom: 0.1rem;
      cursor: pointer;
      
      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0.1rem;
        width: 100%;
        background: ${({ theme }) => theme.global.fontColor};
        height: 0.1rem;
        transform: scaleX(1);
        opacity: .6;
        transform-origin: left;
        transition: transform .7s cubic-bezier(.19,1,.22,1) .2s;
        margin: 0;
        padding: 0;
      }
      
      &-white {
        &:after {
          background: white;
        }
      }
      
      &:hover {
        outline: 0;
        
        &:after {
          transform: scaleX(0);
          transform-origin: right;
          transition-delay: 0s;
        }
      }
    }
    
    &.hover-underline {
      position: relative;
      z-index: 1;
      height: 100%;
      display: inline-block;
      
      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0.1rem;
        background: currentColor;
        opacity: .6;
        transition: transform .7s cubic-bezier(.19, 1, .22, 1) .2s;
        transform: scaleX(0);
        transform-origin: right;
      }
      
      &:hover {
        &:after {
          transform: scaleX(1);
          transform-origin: left;
          transition-delay: 0s;
        }
      }
    }
  }

  input[type="checkbox"] {
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.5rem;
    height: 1.5rem;
    border: 0.1rem solid currentColor;
    border-radius: 0;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    
    &:before {
      content: "";
      width: 0.8rem;
      height: 0.8rem;
      background-color: #334155;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
    }

    &:checked:before {
      transform: scale(1);
    }
    
    &.invert {
      background-color: transparent;
      border-color: white;
      
      &:before {
        background-color: white;
      }
    }
  }
  
  p {
    margin: 0;
    padding: 0;
    font-size: 1.4rem;
    line-height: 2rem;
  }

  #shopify-chat{
    z-index: 2 !important;
    
    iframe {
      z-index: 2 !important;
      right: 0.4rem !important;
      
      ${up('mobile')} {
        right: 4rem !important;  
      }

      ${down('mobile')} {
        &[isopen=true] {
          bottom: 0 !important;
          z-index: 999999 !important;
        }
      }
    }
  }
  
  #dummy-chat-button-iframe {
    z-index: 2 !important;
    right: 0.4rem !important;

    ${up('mobile')} {
      right: 4rem !important;
    }

    ${down('mobile')} {
      &[isopen=true] {
        bottom: 0 !important;
        z-index: 999999 !important;
      }
    }
  }
`;

export default GlobalStyle;
