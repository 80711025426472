export const GAEventAddToCart = (product, variant, quantity) => {
  const [category] = product.collections;
  const itemVariant = product.variants.length > 1 ? variant.title : product.title;
  const currency = product.priceRangeV2.minVariantPrice.currencyCode;
  const item = {
    item_id: variant.sku,
    item_name: product.title,
    item_category: category ? category.title : '',
    item_brand: 'hooprint',
    price: variant.price,
    item_variant: itemVariant,
    quantity
  };

  dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.s
  dataLayer.push({
    event: 'add_to_cart',
    ecommerce: {
      currency,
      value: variant.price,
      items: [{ ...item }]
    }
  });

  // pinterest
  if (typeof pintrk === 'function') {
    pintrk('track', 'addtocart', {
      value: variant.price,
      currency: currency,
      line_items: [
        {
          product_id: item.item_id,
          product_category: item.item_category
        }
      ]
    });
  }
};

export const GAEventRemoveFromCart = product => {
  const itemVariant =
    product.variant.title === 'Default Title' ? product.title : product.variant.title;
  const item = {
    item_id: product.variant.sku,
    item_name: product.title,
    item_brand: 'hooprint',
    price: product.variant.price,
    item_variant: itemVariant,
    quantity: product.quantity
  };

  dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.s
  dataLayer.push({
    event: 'remove_from_cart',
    ecommerce: {
      items: [{ ...item }]
    }
  });
};

export const GAEventViewItem = (product, variant) => {
  const [category] = product.collections;
  const currency = product.priceRangeV2.minVariantPrice.currencyCode;
  const item = {
    item_id: variant.sku,
    item_name: product.title,
    item_category: category ? category.title : '',
    item_brand: 'hooprint',
    price: variant.price,
    quantity: 1
  };

  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'view_item',
    ecommerce: {
      currency,
      value: variant.price,
      items: [{ ...item }]
    }
  });

  // pinterest
  if (typeof pintrk === 'function') {
    pintrk('track', 'pagevisit', {
      line_items: [
        {
          product_id: item.item_id,
          product_category: item.item_category
        }
      ]
    });
  }
};

export const GAEventViewItemList = (products, category) => {
  const items = products.map((product, index) => {
    const [variant] = product.variants;
    return {
      item_name: product.title,
      item_id: variant.sku,
      price: variant.price,
      item_brand: 'hooprint',
      item_category: category.title,
      index: index + 1,
      quantity: 1
    };
  });

  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'view_item_list',
    ecommerce: {
      items
    }
  });

  // pinterest
  if (typeof pintrk === 'function') {
    pintrk('track', 'viewcategory');
  }
};

export const GAEventSelectItem = (product, category, variant) => {
  const currentCategory = Array.isArray(category) ? category[0] : category;
  const currency = product.priceRangeV2 ? product.priceRangeV2.minVariantPrice.currencyCode : 'EUR';
  const item = {
    item_id: variant.sku,
    item_name: product.title,
    item_category: currentCategory ? currentCategory.title : '',
    item_brand: 'hooprint',
    price: variant.price,
    quantity: 1
  };

  dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.s
  dataLayer.push({
    event: 'select_item',
    ecommerce: {
      currency,
      value: variant.price,
      items: [{ ...item }]
    }
  });
  //
  // dataLayer.push({
  //   event: 'purchase',
  //   transaction_id: '1002',
  //   value: 220.0,
  //   currency: 'EUR',
  //   tax: 0.0,
  //   shipping: 0.0,
  //   items: [
  //     {
  //       id: '7585005043927',
  //       name: 'Malie',
  //       quantity: 1,
  //       price: 130.0
  //     },
  //     {
  //       id: '7585005142231',
  //       name: 'Câini',
  //       quantity: 1,
  //       price: 90.0
  //     }
  //   ]
  // });
};
