import Client from 'shopify-buy/index.umd.min';

const client = Client.buildClient({
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
  domain: `${process.env.GATSBY_SHOP_NAME}`
});

const CHECKOUT_LOCALSTORAGE_KEY = 'checkout';

export const createCheckout = () => async dispatch => {
  dispatch({ type: 'LOADING_CHECKOUT', payload: true });
  const newCheckout = await client.checkout.create();
  localStorage.setItem('checkout', newCheckout.id);

  dispatch({
    type: 'SET_CART',
    payload: Object.assign({}, newCheckout)
  });
};

export const getCheckout = (forceUpdate = false) => async (dispatch, getState) => {
  const checkoutId = localStorage.getItem(CHECKOUT_LOCALSTORAGE_KEY);

  if (
    (checkoutId && checkoutId !== 'null' && !getState().cart.checkout) ||
    (forceUpdate && checkoutId)
  ) {
    try {
      const isBrowser = typeof window !== 'undefined';
      const existingCheckoutID = isBrowser ? checkoutId : null;
      dispatch({ type: 'SET_CART', payload: null });
      dispatch({ type: 'LOADING_CHECKOUT', payload: true });
      let fetchCheckout = await client.checkout.fetch(existingCheckoutID);

      // comprobar si el checkout se ha completado, se deberá crear uno nuevo
      if (fetchCheckout.completedAt) {
        await dispatch(createCheckout());
      } else {
        dispatch({
          type: 'SET_CART',
          payload: Object.assign({}, fetchCheckout)
        });
      }

      return getState().cart.checkout;
    } catch (e) {
      localStorage.setItem(CHECKOUT_LOCALSTORAGE_KEY, null);
    }
  } else if (!checkoutId || !getState().cart.checkout || checkoutId === 'null') {
    await dispatch(createCheckout());

    return getState().cart.checkout;
  } else {
    return getState().cart.checkout;
  }
};

export const addVariantToCart = (variantId, quantity = 1, options = []) => async dispatch => {
  dispatch({ type: 'LOADING_CHECKOUT', payload: true });
  dispatch({ type: 'LOADING_VARIANT', payload: true });
  let checkout = await dispatch(getCheckout());

  if (!checkout || !Object.keys(checkout).length) {
    await dispatch(createCheckout());
    checkout = getState().cart.checkout;
  }

  const lineItemsToAdd = [
    { variantId, quantity: parseInt(quantity, 10), customAttributes: options }
  ];

  try {
    const result = await client.checkout.addLineItems(checkout.id, lineItemsToAdd);
    dispatch(showCartSummary(true));

    dispatch({
      type: 'ADD_VARIANT_CART',
      payload: Object.assign({}, result)
    });
  } catch (e) {
    dispatch({ type: 'LOADING_VARIANT', payload: false });
  }
};

export const removeItem = itemId => async (dispatch, getState) => {
  const checkout = getState().cart.checkout;
  const newCheckout = await client.checkout.removeLineItems(checkout.id, [itemId]);

  dispatch({
    type: 'SET_CART',
    payload: Object.assign({}, newCheckout)
  });
};

export const goToCheckout = () => (dispatch, getState) => {
  if (getState().cart.checkout) {
    dispatch({ type: 'LOADING_BUY', payload: true });
    window.location.href = getState().cart.checkout.webUrl;
  }
};

export const loadCart = () => async dispatch => {
  await dispatch(getCheckout(false));
};

export const hasItemsCart = () => (dispatch, getState) => {
  const checkout = getState().cart.checkout;

  if (checkout) {
    return !(!checkout.lineItems || !checkout.lineItems.length);
  }

  return false;
};

export const showCartSummary = isOpen => (dispatch, getState) => {
  const isCartSummaryOpen = isOpen || !getState().cart.isCartSummaryOpen;

  dispatch({ type: 'SHOW_CART_SUMMARY', payload: isCartSummaryOpen });
};
