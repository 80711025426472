export const initialState = {
  checkout: null,
  loadingCheckout: false,
  loadingVariant: false,
  loadingBuy: false,
  isCartSummaryOpen: false
};

const reducer = (state = initialState, { type, payload, ...action }) => {
  switch (type) {
    case 'SHOW_CART_SUMMARY':
      return {
        ...state,
        isCartSummaryOpen: payload
      };
    case 'SET_CART':
      return {
        ...state,
        checkout: payload,
        loadingCheckout: false
      };
    case 'ADD_VARIANT_CART':
      return {
        ...state,
        checkout: payload,
        loadingVariant: false
      };
    case 'LOADING_CHECKOUT':
      return {
        ...state,
        loadingCheckout: payload
      };
    case 'LOADING_VARIANT':
      return {
        ...state,
        loadingVariant: payload
      };
    case 'LOADING_BUY':
      return {
        ...state,
        loadingBuy: payload
      };
    case 'UPDATE_CHECKOUT':
      return {
        ...state,
        lineItems: {
          ...state.data,
          ...payload
        }
      };
    default:
      return state;
  }
};

export default reducer;
